import request from '@/utils/request'

export function shopApplyAdd(params) {
	return request({
		url: '/shop/apply/add',
		method: 'post',
		data: params
	})
}

export function shopApplyEdit(params) {
	return request({
		url: '/shop/apply/edit',
		method: 'post',
		data: params
	})
}

export function shopApplyList(params) {
	return request({
		url: '/shop/apply/list',
		method: 'post',
		data: params
	})
}

export function shopApplyBack(params) {
	return request({
		url: '/shop/apply/back',
		method: 'post',
		params
	})
}

export function shopApplyInfo(params) {
	return request({
		url: '/shop/apply/info',
		method: 'post',
		params
	})
}