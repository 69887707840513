<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
				<div class="search">
					<input placeholder="请输入商品名称" v-model="name" />
					<div class="search-btn el-icon-search" @click="search()"> </div>
				</div>
			</div>
		</div>
		<div class="apply-list-content">
			<div class="table-bar">
				<el-button type="primary" size="small" @click="toUrl('/apply')">发起申请</el-button>
				<div style="font-size: 13px;color: red;">申请通过后,商家后台默认账号密码均为申请单中负责人手机号,请及时登陆后台修改密码</div>
			</div>
			<el-table :data="list" v-loading="loading" height="500px" border stripe style="width: 100%;">
				<el-table-column type="index" width="50" />
				<el-table-column prop="shopName" label="申请店铺名称" />
				<el-table-column prop="serverPhone" label="客服电话" width="100" />
				<el-table-column prop="headName" label="负责人" width="100" />
				<el-table-column prop="status" label="状态" width="100">
					<template slot-scope="scope">
						<el-tag type="primary" size="small" v-if="scope.row.status==0">待审核</el-tag>
						<el-tag type="primary" size="small" v-if="scope.row.status==1">已通过</el-tag>
						<el-tag type="warning" size="small" v-if="scope.row.status==2">驳回</el-tag>
						<el-tag type="warning" size="small" v-if="scope.row.status==3">已撤销</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="申请时间" width="180" />
				<el-table-column prop="checkTime" label="审核时间" width="180" />
				<el-table-column prop="reason" label="驳回原因" />
				<el-table-column label="操作" align="center" width="120">
					<template slot-scope="scope">
						<el-button size="mini" type="text" @click="detail(scope)">查看
						</el-button>
						<el-button size="mini" type="text" @click="back(scope.row.id)" v-if="scope.row.status==0">撤销
						</el-button>
						<el-button size="mini" type="text" @click="toUrl('/apply?id='+scope.row.id)"
							v-if="scope.row.status==2||scope.row.status==3">修改
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-drawer title="申请详情" :visible.sync="open" direction="rtl" size="50%">
			<el-card>
				<el-descriptions title="" direction="vertical" :column="3" border>
					<el-descriptions-item label="店铺名称">{{info.shopName}}</el-descriptions-item>
					<el-descriptions-item label="客服电话">{{info.serverPhone}}</el-descriptions-item>
					<el-descriptions-item label="负责人姓名">{{info.headName}}</el-descriptions-item>
					<el-descriptions-item label="负责人电话">{{info.headPhone}}</el-descriptions-item>
					<el-descriptions-item label="店铺地址">{{info.shopAddr}}</el-descriptions-item>
					<el-descriptions-item label="经营者姓名">{{info.authManagerName}}</el-descriptions-item>
					<el-descriptions-item label="经营者身份证号">{{info.authManagerCard}}</el-descriptions-item>
					<el-descriptions-item label="社会信用代码">{{info.socialCreditCode}}</el-descriptions-item>
					<el-descriptions-item label="经营者身份证正面">
						<viewer :images="[info.authManagerCardFront]">
							<img v-for="(src,index) in [info.authManagerCardFront]" style="height: 60px;width: 100px;"
								:src="src">
						</viewer>
					</el-descriptions-item>
					<el-descriptions-item label="经营者身份证反面">
						<viewer :images="[info.authManagerCardSide]">
							<img v-for="(src,index) in [info.authManagerCardSide]" style="height: 60px;width: 100px;"
								:src="src">
						</viewer>
					</el-descriptions-item>
					<el-descriptions-item label="经营者手持身份证">
						<viewer :images="[info.authManagerCardHead]">
							<img v-for="(src,index) in [info.authManagerCardHead]" style="height: 60px;width: 100px;"
								:src="src">
						</viewer>
					</el-descriptions-item>
					<el-descriptions-item label="营业执照">
						<viewer :images="[info.businessLicense]">
							<img v-for="(src,index) in [info.businessLicense]" style="height: 60px;width: 100px;"
								:src="src">
						</viewer>
					</el-descriptions-item>
					<el-descriptions-item label="药品经营许可证">
						<viewer :images="[info.drugAuth]">
							<img v-for="(src,index) in [info.drugAuth]" style="height: 60px;width: 100px;" :src="src">
						</viewer>
					</el-descriptions-item>
					<el-descriptions-item label="食品经营许可证">
						<viewer :images="[info.foodAuth]">
							<img v-for="(src,index) in [info.foodAuth]" style="height: 60px;width: 100px;" :src="src">
						</viewer>
					</el-descriptions-item>
					<el-descriptions-item label="商户名称">{{info.marchantName}}</el-descriptions-item>
					<el-descriptions-item label="状态">
						<el-tag size="small" type="danger" v-if="info.status==0">待审核</el-tag>
						<el-tag size="small" type="success" v-if="info.status==1">审核通过</el-tag>
						<el-tag size="small" type="warning" v-if="info.status==2">驳回</el-tag>
						<el-tag size="small" type="warning" v-if="info.status==3">撤销</el-tag>
					</el-descriptions-item>
					<el-descriptions-item label="申请时间">{{info.createTime}}</el-descriptions-item>
					<el-descriptions-item label="审核时间">{{info.checkTime}}</el-descriptions-item>
					<el-descriptions-item label="审核备注">{{info.remark}}</el-descriptions-item>
				</el-descriptions>
			</el-card>
		</el-drawer>
		<Footer></Footer>

	</div>
</template>

<script>
	import {
		shopApplyList,
		shopApplyAdd,
		shopApplyBack
	} from '@/api/shop/apply'
	export default {
		data() {
			return {
				name: '',
				list: [],
				loading: false,
				open: false,
				info: {}
			};
		},
		created() {
			this.getList()
		},
		methods: {
			detail(data) {
				this.info = data.row
				this.open = true
			},
			search() {
				this.$router.push({
					path: '/item/search?name=' + this.name
				})
			},
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			getList() {
				this.loading = true
				shopApplyList({}).then(res => {
					this.list = res.data
					this.loading = false
				})
			},
			back(id) {
				shopApplyBack({
					id: id
				}).then(res => {
					this.$message({
						message: '撤销成功',
						type: 'success'
					});
					this.getList()
				})
			}
		},
	};
</script>

<style lang='scss'>
	.apply-list-content {
		width: 80%;
		margin-left: 10%;

		.table-bar {
			height: 50px;
			width: 100%;
			margin-top: 20px;
		}
	}
</style>